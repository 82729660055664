// extracted by mini-css-extract-plugin
export var advisorReviews = "AdvisorReviews-module--advisorReviews--Ege+H";
export var all = "AdvisorReviews-module--all--nO441";
export var button = "AdvisorReviews-module--button--f1dx+";
export var date = "AdvisorReviews-module--date--I0wkl";
export var heading = "AdvisorReviews-module--heading--x1lo0";
export var noReviews = "AdvisorReviews-module--noReviews--f+Q9B";
export var paragraph = "AdvisorReviews-module--paragraph--MHg5W";
export var request = "AdvisorReviews-module--request--n-3tC";
export var review = "AdvisorReviews-module--review--xdF04";
export var sorting = "AdvisorReviews-module--sorting--2GV-n";
export var stars = "AdvisorReviews-module--stars--52D3w";
export var tabs = "AdvisorReviews-module--tabs--+DHw4";