import clsx from "clsx";
import Input from "@js/component/Input";
import React, {DetailedHTMLProps, FormEvent, forwardRef, HTMLAttributes, Ref} from "react";

import * as classNames from "@css/component/AddressFormInput.module.scss";

/**
 * @type AddressFormInputsProps
 */
export type AddressFormInputsProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    inputZipCode?: string;
    onSetInputZipCode: (event: FormEvent<HTMLInputElement>) => void;
    inputHouseNumber?: string;
    onSetInputHouseNumber: (event: FormEvent<HTMLInputElement>) => void;
    inputHouseNumberSuffix?: string;
    onSetInputHouseNumberSuffix: (event: FormEvent<HTMLInputElement>) => void;
    inputStreet?: string;
    onSetInputStreet: (event: FormEvent<HTMLInputElement>) => void;
    inputCity?: string;
    onSetInputCity: (event: FormEvent<HTMLInputElement>) => void;
    initialZipCode?: string;
    initialHouseNumber?: string;
    initialHouseNumberSuffix?: string;
    buildingOwner?: boolean;
    hasBillingAddress?: boolean;
    errors: {
        zipCode?: [];
        houseNumber?: [];
        houseNumberSuffix?: [];
        street?: [];
        city?: [];
    };
};

const AddressFormInputs = (props: AddressFormInputsProps, ref: Ref<HTMLDivElement>) => {
    const {
        inputZipCode,
        onSetInputZipCode,
        inputHouseNumber,
        onSetInputHouseNumber,
        inputHouseNumberSuffix,
        onSetInputHouseNumberSuffix,
        inputStreet,
        onSetInputStreet,
        inputCity,
        onSetInputCity,
        initialZipCode,
        initialHouseNumber,
        initialHouseNumberSuffix,
        buildingOwner,
        hasBillingAddress,
        errors,
        ...restProps
    } = props;

    return (
        <div { ...restProps } ref={ ref }>
            <div className={ classNames.group }>
                <div>
                    <label className={ clsx(
                        classNames.label
                    ) } htmlFor="zip_code">Postcode</label>
                    <Input
                        className={ clsx(classNames.input, classNames.zipCode, {
                            [classNames.error]: errors.zipCode
                        }) }
                        disabled={ !(!(initialZipCode)) }
                        id="zip_code"
                        name="zip_code"
                        onChange={ onSetInputZipCode }
                        pattern="(?:NL-)?(\d{4})\s*([A-Z]{2})"
                        placeholder="1234 AB"
                        required={ (hasBillingAddress || buildingOwner) }
                        value={ inputZipCode }
                    />
                </div>
                <div>
                    <label className={ clsx(
                        classNames.label,
                        classNames.labelHouseNumber
                    ) } htmlFor="house_number">Huisnummer</label>
                    <Input
                        className={ clsx(classNames.input, classNames.houseNumber, {
                            [classNames.error]: errors.houseNumber
                        }) }
                        disabled={ !(!(initialHouseNumber)) }
                        id="house_number"
                        name="house_numbers"
                        onChange={ onSetInputHouseNumber }
                        // pattern="[0-9]+"
                        required={ (hasBillingAddress || buildingOwner) }
                        value={ inputHouseNumber }
                    />
                </div>
                <div>
                    <label className={ clsx(
                        classNames.label,
                        classNames.labelAddition
                    ) } htmlFor="addition">Toevoeging</label>
                    <Input
                        className={ clsx(classNames.input, classNames.addition, {
                            [classNames.error]: errors.houseNumberSuffix
                        }) }
                        disabled={ !(!(initialHouseNumberSuffix)) }
                        id="addition"
                        name="addition"
                        onChange={ onSetInputHouseNumberSuffix }
                        value={ inputHouseNumberSuffix }
                    />
                </div>
            </div>
            <ul className={ classNames.errors }>
                { errors.zipCode &&
                    errors.zipCode.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
                { errors.houseNumber &&
                    errors.houseNumber.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
                { errors.houseNumberSuffix &&
                    errors.houseNumberSuffix.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
            </ul>
            <label className={ classNames.label } htmlFor="street">Straatnaam</label>
            <Input
                className={ clsx(classNames.input, {
                    [classNames.error]: errors.street
                }) }
                id="street"
                name="street"
                onChange={ onSetInputStreet }
                required={ (hasBillingAddress || buildingOwner) }
                value={ inputStreet }
                autoComplete={ hasBillingAddress ? 'billing-street-address' : 'street-address' }
            />
            <ul className={ classNames.errors }>
                { errors.street &&
                    errors.street.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
            </ul>
            <label className={ classNames.label } htmlFor="city">Plaats</label>
            <Input
                className={ clsx(classNames.input, {
                    [classNames.error]: errors.city
                }) }
                id="city"
                name="city"
                onChange={ onSetInputCity }
                required={ (hasBillingAddress || buildingOwner) }
                value={ inputCity }
                autoComplete={ hasBillingAddress ? 'billing-city' : 'address-city' }
            />
            <ul className={ classNames.errors }>
                { errors.city &&
                    errors.city.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
            </ul>
        </div>
    );
};

export default forwardRef(AddressFormInputs);