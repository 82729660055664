import React, {DetailedHTMLProps, HTMLAttributes} from "react";
import SiteFaqQuestion from "@js/component/SiteFaqQuestion";

export type SiteFaqsProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    faqs: {
        node: {
            content: string;
            title: string;
        }
    }[];
};

const SiteFaqs = (props: SiteFaqsProps) => {
    const {
        faqs
    } = props;

    const FaqStructuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity":
            faqs.map((faq) => ({
                "@type": "Question",
                "name": faq.node.title,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": faq.node.title
                }
            }))
    };

    return (
        <>
            { faqs.map((faq, index) => (
                <SiteFaqQuestion
                    key={ index }
                    question={ faq.node.title }
                    answer={ faq.node.content }
                />
            ))}

            <script type="application/ld+json">
                { JSON.stringify(FaqStructuredData) }
            </script>
        </>
    );
};

export default SiteFaqs;