import React, {DetailedHTMLProps, forwardRef, HTMLAttributes, Ref} from "react";
import Heading from "@js/component/Heading";
import Stars from "@js/component/Stars";

import * as classNames from "@css/component/AdvisorReviewsScore.module.scss";

/**
 * @type AdvisorReviewsScoreProps
 */
type AdvisorReviewsScoreProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> & {
    averageReviewsScore: number;
    averageReviewsTotal: number;
    reviewScore: {
        1: number;
        2: number;
        3: number;
        4: number;
        5: number;
    };
};

const AdvisorReviewsScore = (props: AdvisorReviewsScoreProps, ref: Ref<HTMLDivElement>) => {
    const {
        averageReviewsScore,
        averageReviewsTotal,
        reviewScore
    } = props;

    return (
        <div className={ classNames.advisorReviewsScore } ref={ ref }>
            <div className={ classNames.header }>
                <Heading className={ classNames.heading } element="h2" variant="small">
                    Reviews
                </Heading>
                <div className={ classNames.reviewScore }>
                    <Stars
                        backgroundColor="#E5E5E5"
                        className={ classNames.stars }
                        foregroundColor="#FFC402"
                        foregroundPercentage={ (averageReviewsScore * 2) * 10 }
                    />
                    <span className={classNames.totalScore}>
                        { averageReviewsScore }/5 ({ averageReviewsTotal } reviews)
                    </span>
                </div>
            </div>

            <label className={classNames.subHeading}>
                Beoordeling
            </label>

            { Object.entries(reviewScore).reverse().map(([key, score]) => {
                const ratingPercentage = score ? Math.ceil(score / averageReviewsTotal * 100) : 0;
                return (
                    <div className={ classNames.rating } key={ key } >
                        <label>{ `${ key.replace("_", "") } ${ key as unknown as number > 1 ? "sterren" : "ster" }` }</label>
                        <div className={ classNames.ratingBackground }>
                            <span className={ classNames.ratingForeground } style={{ "width": `${ ratingPercentage }%` }} />
                        </div>
                        <span className={ classNames.totalReviews }>{ `${ score } ${ score === 1 ? "review" : "reviews" }` }</span>
                    </div>
                );
            })}
        </div>
    );
};

export default forwardRef(AdvisorReviewsScore);