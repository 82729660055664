import React from "react";

import { DetailedHTMLProps } from "react";
import { InputHTMLAttributes } from "react";

import clsx from "clsx";

import CheckIcon from "@js/component/icon/CheckIcon";

import * as classNames from "@css/component/InputRadio.module.scss";

/**
 * @type InputRadioProps
 */
export type InputRadioProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    labelClassName?: string;
    additionalClassName?: string;
};

/**
 * @const InputRadio
 */
const InputRadio = (props: InputRadioProps) => {
    const {
        children,
        className,
        labelClassName,
        additionalClassName,
        ...restProps
    } = props;

    return (
        <label className={ clsx(classNames.label, labelClassName) }>
            <input { ...restProps } className={ classNames.input } type="radio" />
            <div className={ clsx(classNames.inputRadio, className) }>
                <CheckIcon className={ classNames.icon } />
            </div>
            <div className={ clsx(classNames.inputRadioLabel, additionalClassName) }>
                { children }
            </div>
        </label>
    );
};

export default InputRadio;
