// extracted by mini-css-extract-plugin
export var advisorReviewsScore = "AdvisorReviewsScore-module--advisorReviewsScore--6owOU";
export var header = "AdvisorReviewsScore-module--header--pi4fe";
export var heading = "AdvisorReviewsScore-module--heading--eIyki";
export var rating = "AdvisorReviewsScore-module--rating--uJOiz";
export var ratingBackground = "AdvisorReviewsScore-module--ratingBackground--qJHc6";
export var ratingForeground = "AdvisorReviewsScore-module--ratingForeground--94YaZ";
export var reviewScore = "AdvisorReviewsScore-module--reviewScore--U4kAb";
export var stars = "AdvisorReviewsScore-module--stars--mBPUo";
export var subHeading = "AdvisorReviewsScore-module--subHeading--KvrKK";
export var totalReviews = "AdvisorReviewsScore-module--totalReviews--d7evO";
export var totalScore = "AdvisorReviewsScore-module--totalScore--rJFZ1";