import React, {DetailedHTMLProps, forwardRef, HTMLAttributes, Ref, useEffect, useState} from "react";
import Heading from "@js/component/Heading";
import Stars from "@js/component/Stars";
import Moment from "react-moment";
import clsx from "clsx";
import Button from "@js/component/Button";
import { navigate } from "gatsby";
import AdvisorReviewsScore from "@js/component/AdvisorReviewsScore";

import * as classNames from "@css/component/AdvisorReviews.module.scss";

/**
 * @type AdvisorReviewsProps
 */
type AdvisorReviewsProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children"> & {
    advisor?: {
        companyImage: {
            originalName: string;
            url: string;
        };
        companyName: string;
        deliveryTime: number;
        full: boolean;
        id: number;
        reviewAverage: number;
        reviewAveragePercentage: number;
        reviewTotalCount: number;
        reviews: {
            advisor: number;
            recentReviews: {
                advisor: number;
                billingCity: string;
                billingHouseNumber: number;
                billingHouseNumberSuffix: string;
                billingStreet: string;
                billingZipcode: string;
                buildingOwnerAffix: string;
                buildingOwnerCompanyName: string;
                buildingOwnerInitials: string;
                buildingOwnerName: string;
                buildingOwnerTitle: string;
                createdAt: string;
                deliveryTime: number;
                review: number;
                reviewMessage: string;
                status: string;
                url: string;
            }[];
            scores: {
                1: number;
                2: number;
                3: number;
                4: number;
                5: number;
            };
            stats: {
                averageScore: number;
                totalReviews: number;
                wilsonScore: number;
            };
        }[];
        url: string;
    };
    reviews?: {
        advisor: number;
        billingCity: string;
        billingHouseNumber: number;
        billingHouseNumberSuffix: string;
        billingStreet: string;
        billingZipcode: string;
        buildingOwnerAffix: string;
        buildingOwnerCompanyName: string;
        buildingOwnerInitials: string;
        buildingOwnerName: string;
        buildingOwnerTitle: string;
        createdAt: string;
        deliveryTime: number;
        review: number;
        reviewMessage: string;
        status: string;
        url: string;
    }[];
    reviewsAverageScore?: number,
    reviewsTotalCount?: number,
    reviewScores?: {
        1: number;
        2: number;
        3: number;
        4: number;
        5: number;
    };
    variant?: string;
};

const AdvisorReviews = (props: AdvisorReviewsProps, ref: Ref<HTMLDivElement>) => {
    const {
        advisor,
        reviews,
        reviewsAverageScore,
        reviewsTotalCount,
        reviewScores,
        variant
    } = props;

    const [allAdvisorReviews, setAllAdvisorReviews] = useState([]);
    const [advisorReviews, setAdvisorReviews] = useState([]);
    const [advisorReviewsTotalCount, setAdvisorReviewsTotalCount] = useState(0);
    const [advisorReviewsAverageScore, setAdvisorReviewsAverageScore] = useState(0);
    const [advisorReviewsScores, setAdvisorReviewsScores] = useState({});

    const reviewPercentage = (review: number) => {
        const percentage = review / 5 * 100;
        return percentage < 100 ? percentage : 100;
    }

    const sortReviews = (sortOption) => {
        if("laag" === sortOption) {
            setAdvisorReviews([...advisorReviews].sort((a, b) => (a.review < b.review ? -1 : 1)));
        }

        if("hoog" === sortOption) {
            setAdvisorReviews([...advisorReviews].sort((a, b) => (a.review > b.review ? -1 : 1)));
        }

        if("old" === sortOption) {
            setAdvisorReviews([...advisorReviews].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)));
        }

        if("new" === sortOption) {
            setAdvisorReviews([...advisorReviews].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)));
        }
    }

    const appendReviews = async (currentTotal) => {
        if(advisorReviews.length < advisorReviewsTotalCount) {
            setAdvisorReviews([...advisorReviews, ...allAdvisorReviews.slice(advisorReviews.length, currentTotal)]);
        }
    }

    useEffect(() => {
        if(advisor) {
            setAllAdvisorReviews(advisor.reviews[0].recentReviews);
            setAdvisorReviews(advisor.reviews[0].recentReviews.slice(0, "tabs" === variant ? 2 : 5));
            setAdvisorReviewsTotalCount(advisor.reviews[0].stats.totalReviews);
            setAdvisorReviewsAverageScore(advisor.reviews[0].stats.averageScore);
            setAdvisorReviewsScores(advisor.reviews[0].scores);
        }

        if(reviews) {
            setAllAdvisorReviews(reviews);
            setAdvisorReviews(reviews.slice(0, "tabs" === variant ? 2 : 5));
            setAdvisorReviewsTotalCount(reviewsTotalCount);
            setAdvisorReviewsAverageScore(reviewsAverageScore);
            setAdvisorReviewsScores(reviewScores);
        }
    }, []);

    return (
        <div className={ clsx(classNames.advisorReviews, { [classNames.tabs]: "tabs" === variant, [classNames.request]: "request" === variant }) } ref={ ref }>
            { advisorReviews.length > 0 &&
                <>
                    { "request" !== variant &&
                        <AdvisorReviewsScore
                            averageReviewsScore={ advisorReviewsAverageScore }
                            averageReviewsTotal={ advisorReviewsTotalCount }
                            reviewScore={ advisorReviewsScores }
                        />
                    }

                    { !variant && advisorReviewsTotalCount > 1 &&
                        <select
                            className={ classNames.sorting }
                            defaultValue=""
                            onChange={
                                (event) => sortReviews(event.target.value)
                            }
                        >
                            <option value="" disabled>Sorteren</option>
                            <option value="hoog">Van hoog naar laag</option>
                            <option value="laag">Van laag naar hoog</option>
                            <option value="new">Meest recent</option>
                            <option value="old">Minst recent</option>
                        </select>
                    }

                    { advisorReviews.map((review, index) => (
                        <div className={ classNames.review } key={ index }>
                            <Stars
                                backgroundColor="#E5E5E5"
                                className={ classNames.stars }
                                foregroundColor="#FFC402"
                                foregroundPercentage={ reviewPercentage(review.review) }
                            />
                            <Heading className={ classNames.heading } element="h2">
                                { review.buildingOwnerInitials &&
                                    <span>
                                        { review.buildingOwnerInitials }
                                    </span>
                                }
                                { review.buildingOwnerName &&
                                    <span>
                                        { review.buildingOwnerName }
                                    </span>
                                }
                            </Heading>
                            <p className={ classNames.paragraph }>{ review.reviewMessage }</p>
                            <Moment
                                className={ classNames.date }
                                date={ review.createdAt }
                                format="DD MMMM YYYY"
                                locale="nl"
                            />
                        </div>
                        ))
                    }

                    { !variant && advisorReviewsTotalCount > 5 && advisorReviews.length < advisorReviewsTotalCount &&
                        <Button
                            className={ clsx(classNames.button, classNames.all) }
                            onClick={ () => appendReviews(advisorReviews.length + 7) }
                            type="button"
                            variant="white"
                        >
                            Bekijk meer reviews
                        </Button>
                    }
                </>
            }

            { advisorReviewsTotalCount === 0 &&
                <span className={ classNames.noReviews }>Er zijn (nog) geen klantervaringen voor deze adviseur gevonden</span>
            }

            { "tabs" === variant &&
                <Button
                    className={ classNames.button }
                    onClick={ () => navigate(`/over-ons/energielabel-adviseurs/${ advisor.url }#reviews`) }
                    type="button"
                    variant="white"
                >
                    Alle reviews
                </Button>
            }
        </div>
    );
};

export default forwardRef(AdvisorReviews);